import { useNavigate } from 'react-router-dom'
import { Sidebar as ImportedSidebar } from '@everybyte-io/components'

function Sidebar() {
  const navigate = useNavigate()
  const pathName = window.location.pathname

  return (
    <ImportedSidebar
      title='Dashboard'
      items={[
        {
          label: 'Accounts',
          onClick: () => navigate('/accounts'),
          selected: pathName === '/accounts',
        },
        {
          label: 'Transactions',
          onClick: () => navigate('/transactions'),
          selected: pathName === '/transactions',
        },
        {
          label: 'Payees',
          onClick: () => navigate('/payees'),
          selected: pathName === '/payees',
        },
        {
          label: 'Payments',
          onClick: () => navigate('/payments'),
          selected: pathName === '/payments',
        },
        {
          label: 'Payment Requests',
          onClick: () => navigate('/payment-requests'),
          selected: pathName === '/payment-requests',
        },
        {
          label: 'Settings',
          onClick: () => navigate('/settings'),
          selected: pathName === '/settings',
        },
        {
          label: 'Profile',
          onClick: () => navigate('/profile'),
          selected: pathName === '/profile',
        },
      ]}
    />
  )
}

export default Sidebar
