const myAccounts = [
  {
    _id: 'user_001',
    name: 'Ben Elferink',
    iban: 'IL850115060000128521254',
    bankId: 'discount',
    balance: {
      amount: '1500.00',
      currency: 'usd',
    },
  },
]

export default myAccounts
