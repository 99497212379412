import { useState } from 'react'
import { CLIENT_NAME, PROVIDER_NAME } from '../../constants'

function Row({ title, info }) {
  const [open, setOpen] = useState(false)

  return (
    <div
      className='flex-col grey-border'
      style={{ backgroundColor: 'whitesmoke', cursor: 'pointer' }}
      onClick={() => setOpen((prev) => !prev)}>
      <div className='flex-row align-center' style={{ justifyContent: 'space-between' }}>
        <h4>{title}</h4>
        <span style={{ color: 'var(--blue)', fontSize: '1.4rem', fontWeight: 'bold' }}>&#43;</span>
      </div>

      {open && <p style={{ fontSize: '0.8rem', marginTop: '0.5rem' }}>{info}</p>}
    </div>
  )
}

function Confirm({ bank }) {
  return (
    <div className='flex-col' style={{ maxWidth: '500px' }}>
      <h2 className='text-center'>Consent to read-only access to data</h2>

      <p style={{ margin: '1rem 0', color: 'grey', fontSize: '0.9rem' }}>
        In order to offer this service,&nbsp;
        <span style={{ color: 'black' }}>{CLIENT_NAME}</span>
        &nbsp;needs your approval to access through its trusted partner&nbsp;
        <span style={{ color: 'black' }}>{PROVIDER_NAME}</span>
        &nbsp;the following information from the account you hold at&nbsp;
        <span style={{ color: 'black' }}>{bank.name}:</span>
      </p>

      <h3>Data you will be sharing</h3>

      {[
        {
          title: 'Your Account Details',
          info: 'lorem ipsum',
        },
        {
          title: 'Your Regular Payments',
          info: 'lorem ipsum',
        },
        {
          title: 'Your Account Transactions',
          info: 'lorem ipsum',
        },
        {
          title: 'Your Statements',
          info: 'lorem ipsum',
        },
        {
          title: 'Your Account Feautures and Benefits',
          info: 'lorem ipsum',
        },
        {
          title: 'Your Contact Details',
          info: 'lorem ipsum',
        },
      ].map(({ title, info }, idx) => (
        <Row key={`consent-row:${idx}`} title={title} info={info} />
      ))}
    </div>
  )
}

export default Confirm
