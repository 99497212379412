const paymentRequests = [
  {
    _id: 'rrr8924e10d-0924-49dd-b412-0890a85980bc',
    name: 'Elon Musk',
    amount: {
      label: '420.69',
      value: 42069,
    },
    currency: 'usd',
    description: 'Dogecoin',
    status: 'Successful',
    createdAt: new Date('2022-01-04T12:11:06.543+00:00'),
  },
  {
    _id: 'rrrbea7e8ba-bbb1-4ed9-aab8-115067a8ee18',
    name: 'Eliron Giny',
    amount: {
      label: '1000.00',
      value: 100000,
    },
    currency: 'usd',
    description: 'Gadgets',
    status: 'Successful',
    createdAt: new Date('2022-01-04T12:24:40.593+00:00'),
  },
  {
    _id: 'rrr48efd325-6f6c-4abc-88ee-4ba05e4c3abe',
    name: 'Steve Jobs',
    amount: {
      label: '50.00',
      value: 5000,
    },
    currency: 'usd',
    description: 'E-books',
    status: 'Successful',
    createdAt: new Date('2022-01-04T12:27:50.986+00:00'),
  },
  {
    _id: 'rrr342649e9-79ac-42e0-a6e7-93d7f85133bf',
    name: 'Steve Jobs',
    amount: {
      label: '25.00',
      value: 2500,
    },
    currency: 'usd',
    description: 'E-books',
    status: 'Successful',
    createdAt: new Date('2022-01-04T12:29:05.186+00:00'),
  },
  {
    _id: 'rrrc021c316-620b-46a7-8b5a-99e124269066',
    name: 'Elon Musk',
    amount: {
      label: '69.00',
      value: 6900,
    },
    currency: 'usd',
    description: 'Dogecoin',
    status: 'Successful',
    createdAt: new Date('2022-01-04T12:31:32.947+00:00'),
  },
  {
    _id: 'rrr5de8188c-f5aa-498b-ac3c-91d32b0260cb',
    name: 'Satoshi Nakamoto',
    amount: {
      label: '10000.00',
      value: 1000000,
    },
    currency: 'usd',
    description: 'Bitcoin',
    status: 'pending',
    createdAt: new Date('2022-01-04T12:40:07.774+00:00'),
  },
]

export default paymentRequests
