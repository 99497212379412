import ConsentTable from '../../components/ConsentTable'
import { CLIENT_NAME, PROVIDER_NAME } from '../../constants'

function Confirm({ from, to, inputData }) {
  return (
    <div className='flex-col' style={{ maxWidth: '420px' }}>
      <h2 className='text-center'>Consent to request a payment</h2>

      <p className='text-center' style={{ margin: '1rem 0', color: 'grey', fontSize: '0.9rem' }}>
        <span style={{ color: 'black' }}>{CLIENT_NAME}</span>
        &nbsp;will initiate a payment request via&nbsp;
        <span style={{ color: 'black' }}>{PROVIDER_NAME}</span>
        &nbsp;from your&nbsp;
        <span style={{ color: 'black' }}>{from.name}</span>
        &nbsp;contact with the following details:
      </p>

      <ConsentTable
        fromSup={from.name}
        fromSub={from.iban}
        toSup={to.name}
        toSub={to.iban}
        rows={[
          {
            key: 'Amount:',
            value: `${inputData.amount} USD`,
          },
          {
            key: 'Installments:',
            value: inputData.installments,
          },
          {
            key: 'Date:',
            value: inputData.date,
          },
          {
            key: 'Description:',
            value: inputData.description,
          },
        ]}
      />
    </div>
  )
}

export default Confirm
