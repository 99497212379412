import { useEffect } from 'react'
import { LinearProgress } from '@mui/material'
import { PROVIDER_NAME, TAB_COMMUNICATIONS } from '../../constants'
import Button from '../Button'

function RedirectToBank({ bank, setAuth }) {
  const openUrl = () => {
    window.open(`${window.location.origin}/authorize-bank?bankId=${bank._id}`, '_blank')
  }

  useEffect(() => {
    setTimeout(() => {
      openUrl()
    }, 2000)
  }, [bank]) // eslint-disable-line

  useEffect(() => {
    const interval = setInterval(() => {
      const payload = JSON.parse(localStorage.getItem(TAB_COMMUNICATIONS))

      if (payload) {
        localStorage.removeItem(TAB_COMMUNICATIONS)
        setAuth(payload)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, []) // eslint-disable-line

  return (
    <div className='flex-col' style={{ maxWidth: '420px' }}>
      <h2 className='text-center' style={{ margin: '1rem auto' }}>
        Redirecting
      </h2>

      <div className='flex-row-center'>
        <img src={bank.image} alt='' className='avatar' />
        <h3>{bank.name}</h3>
      </div>

      <p className='text-center' style={{ margin: '1rem 0', color: 'grey', fontSize: '0.9rem' }}>
        You are now leaving&nbsp;
        <span style={{ color: 'black' }}>{PROVIDER_NAME}</span>
        &nbsp;and we are securely transferring you over to&nbsp;
        <span style={{ color: 'black' }}>{bank.name}</span>
      </p>

      <LinearProgress />

      <Button onClick={openUrl} transparent style={{ margin: '1rem 0 0 0' }}>
        Click here to open link manually.
      </Button>
    </div>
  )
}

export default RedirectToBank
