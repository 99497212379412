import { Modal as MuiModal } from '@mui/material'

function Modal({ children, open, onClose }) {
  return (
    <MuiModal open={open} onClose={onClose} style={{ display: 'grid', placeItems: 'center' }}>
      <div
        className='flex-col'
        style={{
          width: 'fit-content',
          padding: '1.5rem',
          backgroundColor: 'whitesmoke',
          borderRadius: '0.5rem',
        }}>
        {children}
      </div>
    </MuiModal>
  )
}

export default Modal
