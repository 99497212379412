function BalanceGreenRed({ amount, currency = 'usd', style = {} }) {
  return (
    <div
      className='flex-row'
      style={{ width: 'fit-content', alignItems: 'center', fontSize: '0.9rem', ...style }}>
      <span style={{ color: Number(amount) > 0 ? 'green' : 'red' }}>{amount}</span>
      &nbsp;{currency.toUpperCase()}
    </div>
  )
}

export default BalanceGreenRed
