import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import { Routes, Route, Navigate } from 'react-router-dom'
import Accounts from './pages/Accounts'
import Transactions from './pages/Transactions'
import PaymentsPage from './pages/Payments'
import PaymentRequestsPage from './pages/PaymentRequests'
import AuthorizeBank from './pages/AuthorizeBank'

function App() {
  return (
    <div>
      <Navbar />
      <div className='flex-row'>
        <Sidebar />
        <main>
          <Routes>
            <Route exact path='/accounts' element={<Accounts />} />
            <Route exact path='/transactions' element={<Transactions />} />
            <Route exact path='/payments' element={<PaymentsPage />} />
            <Route exact path='/payment-requests' element={<PaymentRequestsPage />} />
            <Route exact path='/authorize-bank' element={<AuthorizeBank />} />

            <Route path='*' element={<Navigate to='/accounts' />} />
          </Routes>
        </main>
      </div>
    </div>
  )
}

export default App
