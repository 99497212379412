const payments = [
  {
    _id: '8924e10d-0924-49dd-b412-0890a85980bc',
    name: 'Ben Elferink',
    amount: {
      label: '420.69',
      value: 42069,
    },
    currency: 'usd',
    description: 'Video games',
    status: 'Successful',
    createdAt: new Date('2022-01-04T12:11:06.543+00:00'),
  },
  {
    _id: 'bea7e8ba-bbb1-4ed9-aab8-115067a8ee18',
    name: 'Ben Elferink',
    amount: {
      label: '100.00',
      value: 10000,
    },
    currency: 'usd',
    description: 'Accessories',
    status: 'Successful',
    createdAt: new Date('2022-01-04T12:24:40.593+00:00'),
  },
  {
    _id: '48efd325-6f6c-4abc-88ee-4ba05e4c3abe',
    name: 'Ben Elferink',
    amount: {
      label: '500.00',
      value: 50000,
    },
    currency: 'usd',
    description: 'Gadgets',
    status: 'Successful',
    createdAt: new Date('2022-01-04T12:27:50.986+00:00'),
  },
  {
    _id: '342649e9-79ac-42e0-a6e7-93d7f85133bf',
    name: 'Ben Elferink',
    amount: {
      label: '250.00',
      value: 25000,
    },
    currency: 'usd',
    description: 'E-books',
    status: 'Successful',
    createdAt: new Date('2022-01-04T12:29:05.186+00:00'),
  },
  {
    _id: 'c021c316-620b-46a7-8b5a-99e124269066',
    name: 'Ben Elferink',
    amount: {
      label: '69.00',
      value: 6900,
    },
    currency: 'usd',
    description: 'Comics',
    status: 'Successful',
    createdAt: new Date('2022-01-04T12:31:32.947+00:00'),
  },
  {
    _id: '5de8188c-f5aa-498b-ac3c-91d32b0260cb',
    name: 'Ben Elferink',
    amount: {
      label: '1000.00',
      value: 100000,
    },
    currency: 'usd',
    description: 'Crypto exchange',
    status: 'pending',
    createdAt: new Date('2022-01-04T12:40:07.774+00:00'),
  },
]

export default payments
