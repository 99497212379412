import { SearchBar } from '@everybyte-io/components'
import { useState } from 'react'
import banksImported from '../../data/banks'

function SelectBank({ setItem, selectedItemId }) {
  const [search, setSearch] = useState('')
  const items = banksImported ?? []

  const handleSelect = (id) => {
    const item = items.find((item) => item._id === id)

    if (!item) {
      console.error('Unexpected! Item not found!')
      setItem(null)
    } else {
      setItem(item)
    }
  }

  const itemStyle = {
    width: '200px',
    margin: '0.5rem',
  }

  return (
    <div className='flex-col'>
      <SearchBar
        margin='0 1rem 1rem 1rem'
        backgroundColor='whitesmoke'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className='flex-row' style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
        {items.map(({ _id, name, image, site }, idx) =>
          !search || (search && name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ? (
            <div
              key={`bank:${_id}:${idx}`}
              className={`flex-col grey-border ${
                selectedItemId === _id ? 'blue-shadow' : 'blue-shadow-hover'
              }`}
              style={{ ...itemStyle, cursor: 'pointer' }}
              onClick={() => handleSelect(_id)}>
              <img src={image} alt='' className='avatar' />
              <div>
                <h3>{name}</h3>
                <span className='small-grey-text'>{site}</span>
              </div>
            </div>
          ) : null,
        )}

        {items.length % 2 !== 0 || items.length % 3 !== 0 ? <div style={itemStyle} /> : null}
      </div>
    </div>
  )
}

export default SelectBank
