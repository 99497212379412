import { Fragment } from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import Button from '../Button'
import accountsImported from '../../data/my_accounts'
import BalanceGreenRed from '../BalanceGreenRed'
import banks from '../../data/banks'

function SelectBankAccount({ setItem, selectedItemId }) {
  const items = accountsImported ?? []

  const handleSelect = (e) => {
    const id = e.target.value
    const item = items.find((item) => item._id === id)

    if (!item) {
      console.error('Unexpected! Item not found!')
      setItem(null)
    } else {
      setItem(item)
    }
  }

  const clickAddItem = () => {
    alert('not supported yet')
  }

  const accountsByBank = {}

  items.forEach((account) => {
    const foundBank = banks.find((bank) => bank._id === account.bankId)

    if (!foundBank) {
      return console.warn(`No bank found for bankId: ${account.bankId}`)
    }

    if (accountsByBank[foundBank._id]) {
      accountsByBank[foundBank._id].accounts.push(account)
    } else {
      accountsByBank[foundBank._id] = { bank: foundBank, accounts: [account] }
    }
  })

  return (
    <div className='flex-col'>
      <RadioGroup aria-label='my-bank-accounts'>
        {Object.values(accountsByBank).map(({ bank, accounts }) => (
          <Fragment key={`account-section:${bank._id}`}>
            <div className='flex-row align-center' style={{ margin: '1rem 0 0.5rem 0' }}>
              <img src={bank.image} alt='' className='avatar' />
              <h3 style={{ fontSize: '1.1rem' }}>{bank.name}</h3>
            </div>

            {accounts.map(({ _id, name, iban, balance }) => (
              <div
                key={_id}
                className='flex-row grey-border'
                style={{ width: '400px', justifyContent: 'space-between' }}>
                <div className='flex-row' style={{ width: 'fit-content' }}>
                  <FormControlLabel
                    control={<Radio />}
                    label=''
                    value={_id}
                    checked={selectedItemId === _id}
                    onChange={handleSelect}
                  />
                  <div>
                    <h3>{name}</h3>
                    <span className='small-grey-text'>{iban}</span>
                  </div>
                </div>

                <BalanceGreenRed amount={balance.amount} currency={balance.currency} />
              </div>
            ))}
          </Fragment>
        ))}
      </RadioGroup>

      <Button onClick={clickAddItem} transparent style={{ marginTop: '1rem' }}>
        Choose another bank account
      </Button>
    </div>
  )
}

export default SelectBankAccount
