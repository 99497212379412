import { SearchBar } from '@everybyte-io/components'
import DataTable from '../../components/DataTable'
import accounts from '../../data/my_accounts'
import transactions from '../../data/transactions.js'
import * as consts from '../../constants'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useState } from 'react'

function Transactions() {
  const [search, setSearch] = useState('')
  const [selectedAccount, setSelectedAccount] = useState(accounts[0])

  return (
    <div className='page grey-shadow'>
      <div className='page-head'>
        <h2>Accounts</h2>

        <div className='flex-row align-center' style={{ width: 'fit-content' }}>
          <SearchBar
            margin='0 1rem 0 0'
            backgroundColor='whitesmoke'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <FormControl style={{ maxWidth: '230px' }}>
            <InputLabel id='account-selector'>Account</InputLabel>
            <Select
              id='account-selector'
              labelId='account-selector'
              label='Account'
              value={selectedAccount}
              onChange={(e) => setSelectedAccount(e.target.value)}>
              {accounts.map((acc) => (
                <MenuItem key={`account:${acc._id}`} value={acc}>
                  {acc.name} - {acc.iban}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <DataTable
        dataType={consts.TRANSACTIONS}
        data={transactions.filter(
          (item) =>
            item.accountId === selectedAccount._id &&
            (!search ||
              (search && item.category.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
              (search && item.merchant.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
              (search && item.description.toLowerCase().indexOf(search.toLowerCase()) !== -1)),
        )}
      />
    </div>
  )
}

export default Transactions
