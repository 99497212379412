import { Fragment } from 'react'
import BalanceGreenRed from './BalanceGreenRed'
import { ACCOUNTS, TRANSACTIONS, PAYMENTS, PAYMENT_REQUESTS } from '../constants'
import banks from '../data/banks'

const sortCreatedAt = (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)

const mapAccounts = (item, idx) => (
  <tr key={`tr:${idx}:${item._id}`}>
    <td>
      <h3>{item.name}</h3>
      <span className='small-grey-text'>{item.iban}</span>
    </td>
    <td>
      <BalanceGreenRed
        amount={item.balance.amount}
        currency={item.balance.currency}
        style={{ margin: '0 0 0 auto' }}
      />
    </td>
  </tr>
)

const mapTransactions = (item, idx) => (
  <tr key={`tr:${idx}:${item._id}`}>
    <td>
      <p>{item.category}</p>
    </td>
    <td>
      <p>{item.merchant}</p>
    </td>
    <td style={{ maxWidth: 'unset', width: '100%' }}>
      <p style={{ maxWidth: 'unset', width: '100%', color: 'grey' }}>{item.description}</p>
    </td>
    <td>
      <p>
        -{item.amount} {item.currency}
      </p>
    </td>
  </tr>
)

const mapPayments = (item, idx) => (
  <tr key={`tr:${idx}:${item._id}`}>
    <td>
      <p>{item.name}</p>
    </td>
    <td>
      <p>{item.description}</p>
    </td>
    <td>
      <p>{new Date(item.createdAt).toISOString().split('T')[0]}</p>
    </td>
    <td style={{ color: item.status.toLowerCase() === 'successful' ? 'green' : 'red' }}>
      <p>{item.status.toLowerCase()}</p>
    </td>
    <td>
      <p>
        {item.amount.label} {item.currency.toUpperCase()}
      </p>
    </td>
  </tr>
)

function DataTable({ dataType = '', data = [] }) {
  switch (dataType) {
    case ACCOUNTS: {
      const accountsByBank = {}

      data.forEach((account) => {
        const foundBank = banks.find((bank) => bank._id === account.bankId)

        if (!foundBank) {
          return console.warn(`No bank found for bankId: ${account.bankId}`)
        }

        if (accountsByBank[foundBank._id]) {
          accountsByBank[foundBank._id].accounts.push(account)
        } else {
          accountsByBank[foundBank._id] = { bank: foundBank, accounts: [account] }
        }
      })

      return (
        <table className='data-table'>
          {Object.values(accountsByBank).map(({ bank, accounts }) => (
            <Fragment key={`table-section:${bank._id}`}>
              <thead>
                <tr>
                  <th>
                    <div className='flex-row align-center'>
                      <img src={bank.image} alt='' className='avatar' />
                      <h3 style={{ fontSize: '1.1rem' }}>{bank.name}</h3>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>{accounts.map(mapAccounts)}</tbody>
            </Fragment>
          ))}
        </table>
      )
    }

    case TRANSACTIONS: {
      const transactions = data

      return (
        <table className='data-table'>
          <thead>
            <tr>
              {['Category', 'Merchant', 'Description', 'Amount'].map((txt, idx) => (
                <th key={`th:${idx}:${txt}`}>{txt}</th>
              ))}
            </tr>
          </thead>
          <tbody>{transactions.sort(sortCreatedAt).map(mapTransactions)}</tbody>
        </table>
      )
    }

    case PAYMENTS:
    case PAYMENT_REQUESTS: {
      const payments = data

      return (
        <table className='data-table'>
          <thead>
            <tr>
              {['From', 'Description', 'Date', 'Status', 'Amount'].map((txt, idx) => (
                <th key={`th:${idx}:${txt}`}>{txt}</th>
              ))}
            </tr>
          </thead>
          <tbody>{payments.sort(sortCreatedAt).map(mapPayments)}</tbody>
        </table>
      )
    }

    default: {
      return null
    }
  }
}

export default DataTable
