import { useState } from 'react'
import getQuery from '../../functions/get-query'
import banks from '../../data/banks'
import styles from './AuthorizeBank.module.css'
import { TAB_COMMUNICATIONS } from '../../constants'

function AuthorizeBank() {
  const { bankId } = getQuery(window.location.search)
  const bank = banks.find((item) => item._id === bankId)

  const [formData, setFormData] = useState({
    user: '',
    pass: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const clickAuthorize = () => {
    if (!formData['user'] || !formData['pass']) {
      return alert('bad credentials')
    }

    localStorage.setItem(TAB_COMMUNICATIONS, JSON.stringify(formData))
    alert('Succesfully authorized')

    setTimeout(() => {
      window.close()
    }, 500)
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h1>{bank?.name ?? 'Bank Name'}</h1>

        <div className={`${styles.container} ${styles.form}`}>
          <input
            placeholder='Enter your ID'
            type='id'
            name='user'
            value={formData['user']}
            onChange={handleChange}
          />
          <input
            placeholder='Enter password'
            type='password'
            name='pass'
            value={formData['pass']}
            onChange={handleChange}
          />
          <button onClick={clickAuthorize}>Authorize</button>
        </div>
      </div>

      <ul className={styles.bgBubbles}>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  )
}

export default AuthorizeBank
