import whiteLogo from '../assets/logo-white.png'

const LOGO_URL = whiteLogo
const TAB_COMMUNICATIONS = 'Th!s_k3y_!zUniqu3'

const CLIENT_NAME = 'Bank HaPoalim'
const PROVIDER_NAME = 'Open Finance'

const ACCOUNTS = 'ACCOUNTS'
const TRANSACTIONS = 'TRANSACTIONS'
const PAYMENTS = 'PAYMENTS'
const PAYMENT_REQUESTS = 'PAYMENT_REQUESTS'

const ACCOUNTS_PHASE_MAX = 3
const ACCOUNTS_PHASE_1 = 'Choose Bank'
const ACCOUNTS_PHASE_2 = 'Confirm Consent'
const ACCOUNTS_PHASE_3 = 'Bank Redirect'

const PAYMENTS_INSTALLMENTS = 36
const PAYMENTS_PHASE_MAX = 4
const PAYMENTS_PHASE_1 = 'Choose Account'
const PAYMENTS_PHASE_2 = 'Choose Payee'
const PAYMENTS_PHASE_3 = 'Input Amount'
const PAYMENTS_PHASE_4 = 'Confirm Consent'
const PAYMENT_REQUESTS_PHASE_MAX = 4
const PAYMENT_REQUESTS_PHASE_1 = 'Choose Payer'
const PAYMENT_REQUESTS_PHASE_2 = 'Choose Account'
const PAYMENT_REQUESTS_PHASE_3 = 'Input Amount'
const PAYMENT_REQUESTS_PHASE_4 = 'Confirm Request'

export {
  LOGO_URL,
  TAB_COMMUNICATIONS,
  CLIENT_NAME,
  PROVIDER_NAME,
  ACCOUNTS,
  TRANSACTIONS,
  PAYMENTS,
  PAYMENT_REQUESTS,
  ACCOUNTS_PHASE_MAX,
  ACCOUNTS_PHASE_1,
  ACCOUNTS_PHASE_2,
  ACCOUNTS_PHASE_3,
  PAYMENTS_INSTALLMENTS,
  PAYMENTS_PHASE_MAX,
  PAYMENTS_PHASE_1,
  PAYMENTS_PHASE_2,
  PAYMENTS_PHASE_3,
  PAYMENTS_PHASE_4,
  PAYMENT_REQUESTS_PHASE_MAX,
  PAYMENT_REQUESTS_PHASE_1,
  PAYMENT_REQUESTS_PHASE_2,
  PAYMENT_REQUESTS_PHASE_3,
  PAYMENT_REQUESTS_PHASE_4,
}
