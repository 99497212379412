const transactions = [
  {
    _id: 'transaction-0890a85980bc',
    accountId: 'user_001',
    category: 'Crypto',
    merchant: 'Binance',
    description: 'Dogecoin (DOGE)',
    amount: '420.69',
    currency: 'usd',
    createdAt: new Date('2022-01-04T12:11:06.543+00:00'),
  },
  {
    _id: 'transaction-0890a8598qwe',
    accountId: 'user_001',
    category: 'Crypto',
    merchant: 'Binance',
    description: 'Cardano (ADA)',
    amount: '10000.00',
    currency: 'usd',
    createdAt: new Date('2022-01-04T12:00:06.543+00:00'),
  },
  {
    _id: 'transaction-0890a8598pol',
    accountId: 'user_001',
    category: 'Food and Drinks',
    merchant: 'McDonalds',
    description: 'Lunch',
    amount: '19.99',
    currency: 'usd',
    createdAt: new Date('2022-01-07T11:00:00.543+00:00'),
  },
  {
    _id: 'transaction-0890a8598pos',
    accountId: 'user_001',
    category: 'Gadgets and Electronics',
    merchant: 'iDigital',
    description: 'New iPhone',
    amount: '399.99',
    currency: 'usd',
    createdAt: new Date('2022-01-01T11:00:00.543+00:00'),
  },
]

export default transactions
