import { useState } from 'react'
import myAccounts from '../../data/my_accounts'
import * as consts from '../../constants'
import Button from '../../components/Button'
import DataTable from '../../components/DataTable'
import Journey from '../../journeys/Accounts'
import JourneyStepProvider, { JourneyStepContext } from '../../contexts/JourneyStepContext'

function Accounts() {
  const [previewMode, setPreviewMode] = useState(true)

  const togglePreviewMode = () => {
    setPreviewMode((prev) => !prev)
  }

  return (
    <JourneyStepProvider maxSteps={consts.PAYMENT_REQUESTS_PHASE_MAX}>
      <JourneyStepContext.Consumer>
        {({ step, reset }) => {
          return previewMode ? (
            <div className='page grey-shadow'>
              <div className='page-head'>
                <h2>Accounts</h2>
                <Button onClick={togglePreviewMode}>&#43; Connect Bank</Button>
              </div>
              <DataTable dataType={consts.ACCOUNTS} data={myAccounts} />
            </div>
          ) : (
            <div className='page grey-shadow'>
              <div className='page-head'>
                <h2>
                  <span>Accounts &gt; Connect Bank &gt;</span> {consts[`ACCOUNTS_PHASE_${step}`]}
                </h2>
                <Button
                  onClick={() => {
                    if (window.confirm('Are you sure you want to cancel this session?')) {
                      reset()
                      togglePreviewMode()
                    }
                  }}
                  transparent
                  style={{ fontSize: '1.5rem' }}>
                  &times;
                </Button>
              </div>
              <Journey
                onSuccess={() => {
                  reset()
                  togglePreviewMode()
                }}
              />
            </div>
          )
        }}
      </JourneyStepContext.Consumer>
    </JourneyStepProvider>
  )
}

export default Accounts
