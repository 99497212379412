function ConsentTable({ fromSup, fromSub, toSup, toSub, rows = [] }) {
  return (
    <table className='consent-table'>
      <tbody>
        <tr>
          <td>From:</td>
          <td>
            <p>
              {fromSup}
              <br />
              <span>{fromSub}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td>To:</td>
          <td>
            <p>
              {toSup}
              <br />
              <span>{toSub}</span>
            </p>
          </td>
        </tr>
        {rows.map((item, idx) => (
          <tr key={`tr:${idx}`}>
            <td>{item.key}</td>
            <td>{item.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ConsentTable
