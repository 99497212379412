import { useState } from 'react'
import { Chip } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'

function CopyText({ text = '' }) {
  const [isCopied, setIsCopied] = useState(false)

  const clickCopy = () => {
    if (!isCopied) {
      setIsCopied(true)
      navigator.clipboard.writeText(text)
      setTimeout(() => {
        setIsCopied(false)
      }, 1000)
    }
  }

  return (
    <Chip
      label={isCopied ? 'Copied 👍' : text}
      onClick={clickCopy}
      onDelete={clickCopy}
      deleteIcon={<ContentCopy />}
      style={{
        width: '350px',
        margin: '2rem auto 1rem auto',
        backgroundColor: 'var(--grey)',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    />
  )
}

export default CopyText
