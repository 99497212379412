import Button from '../Button'
import contactsImported from '../../data/contacts'

function SelectPayContact({ setItem, selectedItemId }) {
  const items = contactsImported ?? []

  const handleSelect = (id) => {
    const item = items.find((item) => item._id === id)

    if (!item) {
      console.error('Unexpected! Item not found!')
      setItem(null)
    } else {
      setItem(item)
    }
  }

  const clickAddItem = () => {
    alert('not supported yet')
  }

  const itemStyle = {
    width: '240px',
    height: '85px',
    margin: '0.5rem auto',
    padding: '0.5rem 1rem',
    justifyContent: 'space-between',
    cursor: 'pointer',
  }

  return (
    <div className='flex-row' style={{ flexWrap: 'wrap' }}>
      {items.map(({ _id, name, iban, profilePicture, note }) => (
        <div
          key={_id}
          className={`flex-col grey-border ${selectedItemId === _id ? 'blue-shadow' : 'blue-shadow-hover'}`}
          style={itemStyle}
          onClick={() => handleSelect(_id)}>
          <div className='flex-row' style={{ width: 'fit-content' }}>
            <img src={profilePicture} alt='' className='avatar' />
            <div>
              <h4>{name}</h4>
              <span className='smaller-grey-text'>{iban}</span>
            </div>
          </div>

          <p className='small-grey-text'>{note}</p>
        </div>
      ))}

      <Button
        onClick={clickAddItem}
        invert
        style={{
          ...itemStyle,
          fontSize: '1.5rem',
        }}>
        &#43; New Contact
      </Button>

      {items.length % 2 === 0 && (
        <div className='grey-border' style={{ ...itemStyle, border: 'none', cursor: 'unset' }} />
      )}
    </div>
  )
}

export default SelectPayContact
