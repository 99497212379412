import { useState } from 'react'
import { useSteps } from '../../contexts/JourneyStepContext'
import accountsImported from '../../data/my_accounts'
import transactionsImported from '../../data/transactions'
import * as consts from '../../constants'
import Button from '../../components/Button'
import Stepper from '../../components/Stepper'
import SelectBank from '../../components/JourneyComponents/SelectBank'
import Confirm from './Confirm'
import RedirectToBank from '../../components/JourneyComponents/RedirectToBank'
import Modal from '../../components/Modal'

function Journey({ onSuccess }) {
  const { step, increment, decrement } = useSteps()

  const [bank, setBank] = useState(null)
  const [authorization, setAuthorization] = useState(null)

  const clickConfirm = () => {
    const accountId = `user_${Math.random() * 999999999}-${Math.random() * 999999999}`

    accountsImported.push({
      _id: accountId,
      name: 'Dummy Account',
      iban: `IL${Math.random() * 999999999}`,
      bankId: bank._id,
      balance: {
        amount: '11.00',
        currency: 'usd',
      },
    })

    transactionsImported.push({
      _id: `transaction_${accountId}`,
      accountId,
      category: 'Tests and Demos',
      merchant: 'Open Finance',
      description: 'Dummy transaction data',
      amount: '11.00',
      currency: 'usd',
      createdAt: new Date(),
    })

    onSuccess()
  }

  return (
    <div className='flex-col' style={{ maxWidth: '650px' }}>
      <Stepper activeStep={step - 1} product={consts.ACCOUNTS} />

      <div style={{ margin: '2rem auto' }}>
        {(() => {
          switch (step) {
            case 1:
              return <SelectBank setItem={setBank} selectedItemId={bank?._id ?? ''} />
            case 2:
              return <Confirm bank={bank} />
            case 3:
              return <RedirectToBank bank={bank} setAuth={setAuthorization} />
            default:
              return null
          }
        })()}
      </div>

      {step !== consts.ACCOUNTS_PHASE_MAX ? (
        <div className='flex-row-center'>
          <Button onClick={decrement} disabled={step === 1} invert>
            Back
          </Button>
          <Button onClick={increment} disabled={step === 1 && !bank}>
            {step === 2 ? 'Confirm' : 'Proceed'}
          </Button>
        </div>
      ) : null}

      <Modal open={Boolean(authorization)} onClose={clickConfirm}>
        <h2 className='text-center'>Authorization Complete!</h2>
        <p className='text-center'>You can now safely exit this session.</p>
      </Modal>
    </div>
  )
}

export default Journey
