import { useEffect, useState } from 'react'
import { Input } from '@everybyte-io/components'
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import { PAYMENTS_INSTALLMENTS } from '../../constants'

function InputAmount({ data, setData }) {
  const [formData, setFormData] = useState(
    data ?? {
      amount: 10.0,
      installments: 1,
      description: '',
      date: new Date().toISOString().split('T')[0],
    },
  )

  const handleChange = (name, val) => {
    setFormData((prev) => ({
      ...prev,
      [name]: val,
    }))
  }

  useEffect(() => {
    setData(formData)
  }, [formData]) // eslint-disable-line

  return (
    <div className='flex-col'>
      <div className='flex-row'>
        <Input
          label='Amount (USD)'
          placeholder='15.00'
          type='number'
          name='amount'
          value={formData['amount']}
          setValue={(val) => handleChange('amount', val)}
          required
          style={{ margin: '0 0.1rem 0.3rem 0' }}
        />

        <FormControl fullWidth>
          <InputLabel id='payments-installments-selector'>Installments</InputLabel>
          <Select
            id='payments-installments-selector'
            labelId='payments-installments-selector'
            label='Installments'
            name='installments'
            value={formData['installments']}
            onChange={(e) => handleChange('installments', e.target.value)}
            required
            style={{ margin: '0 0 0.3rem 0.1rem', width: '100%', color: 'black' }}>
            {new Array(PAYMENTS_INSTALLMENTS).fill(1).map((one, idx) => {
              const val = idx + one

              return (
                <MenuItem key={`installment:${val}`} value={val}>
                  {val}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>

      <div className='flex-row'>
        <Input
          label='Description'
          placeholder='Video games'
          name='description'
          value={formData['description']}
          setValue={(val) => handleChange('description', val)}
          required
          style={{ margin: '0.3rem 0.1rem 0 0' }}
        />

        <Input
          label='Date'
          placeholder='12/21/1996'
          type='date'
          name='date'
          value={formData['date']}
          setValue={(val) => handleChange('date', val)}
          required
          style={{ margin: '0.3rem 0 0 0.1rem' }}
        />
      </div>
    </div>
  )
}

export default InputAmount
