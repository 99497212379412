import { useState } from 'react'
import { useSteps } from '../../contexts/JourneyStepContext'
import paymentsImported from '../../data/payments'
import * as consts from '../../constants'
import Button from '../../components/Button'
import Stepper from '../../components/Stepper'
import FromToBar from '../../components/FromToBar'
import SelectBankAccount from '../../components/JourneyComponents/SelectBankAccount'
import SelectPayContact from '../../components/JourneyComponents/SelectPayContact'
import InputAmount from '../../components/JourneyComponents/InputAmount'
import Confirm from './Confirm'

function Journey({ onSuccess }) {
  const { step, increment, decrement } = useSteps()

  const [account, setAccount] = useState(null)
  const [contact, setContact] = useState(null)
  const [inputData, setInputData] = useState(null)

  const clickConfirm = () => {
    if (window.confirm('Are you sure the payment information is correct?')) {
      paymentsImported.push({
        _id: `${Math.random() * 999999999}-${Math.random() * 999999999}-${Math.random() * 999999999}`,
        name: account.name,
        amount: {
          label: String(inputData.amount).indexOf('.') !== -1 ? inputData.amount : `${inputData.amount}.00`,
          value: inputData.amount * 100,
        },
        currency: 'usd',
        description: inputData.description,
        status: 'pending',
        createdAt: new Date(inputData.date),
      })

      onSuccess()
    }
  }

  return (
    <div className='flex-col' style={{ maxWidth: '500px' }}>
      <Stepper activeStep={step - 1} product={consts.PAYMENTS} />

      <div style={{ margin: '2rem auto' }}>
        {(() => {
          switch (step) {
            case 1:
              return <SelectBankAccount setItem={setAccount} selectedItemId={account?._id ?? ''} />
            case 2:
              return <SelectPayContact setItem={setContact} selectedItemId={contact?._id ?? ''} />
            case 3:
              return (
                <div className='flex-col'>
                  <FromToBar
                    fromSup={account?.name ?? ''}
                    fromSub={account?.iban ?? ''}
                    toSup={contact?.name ?? ''}
                    toSub={contact?.iban ?? ''}
                  />
                  <InputAmount data={inputData} setData={setInputData} />
                </div>
              )
            case 4:
              return <Confirm from={account} to={contact} inputData={inputData} />
            default:
              return null
          }
        })()}
      </div>

      <div className='flex-row-center'>
        <Button onClick={decrement} disabled={step === 1} invert>
          Back
        </Button>
        <Button
          onClick={step === consts.PAYMENTS_PHASE_MAX ? clickConfirm : increment}
          disabled={
            (step === 1 && !account) ||
            (step === 2 && !contact) ||
            (step === 3 &&
              (!inputData ||
                !inputData['amount'] ||
                !inputData['installments'] ||
                !inputData['description'] ||
                !inputData['date']))
          }>
          {step === consts.PAYMENTS_PHASE_MAX ? 'Confirm' : 'Proceed'}
        </Button>
      </div>
    </div>
  )
}

export default Journey
