import React from 'react'
import { DoubleArrow } from '@mui/icons-material'

function FromToBar({ fromSup, fromSub, toSup, toSub }) {
  const itemStyle = {
    marginBottom: '2rem',
    padding: '0.5rem 1rem',
    borderRadius: '7px',
    backgroundColor: 'whitesmoke',
    color: 'grey',
    fontSize: '0.8rem',
  }

  return (
    <div className='flex-row' style={itemStyle}>
      <div>
        From: <span style={{ color: 'black' }}>{fromSup}</span>
        <br />
        {fromSub}
      </div>
      <DoubleArrow style={{ margin: 'auto 1rem' }} />
      <div>
        To: <span style={{ color: 'black' }}>{toSup}</span>
        <br />
        {toSub}
      </div>
    </div>
  )
}

export default FromToBar
